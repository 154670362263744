@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap");

.member-main-div {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  margin-left: 40px;
  height: 620px;
}

.add-team-member {
  padding: 20px;
  width: 500px;
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  height: 500px;

  .title-member {
    text-align: left;
    margin-bottom: 20px;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 600;
    color: #333;
    font-size: 35px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    div {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
        color: #333;
        font-family: "Kumbh Sans", sans-serif;
        font-weight: 500;
      }

      .input-members {
        width: 96%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      .input-members::placeholder {
        color: #c9bfbf;
        font-size: 14px;
        font-family: "Kumbh Sans", sans-serif;
      }
    }

    .select-add-member {
      width: 100%;
      height: 40px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .button-add-team {
      width: 190px;
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #00a5a2;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #008d8b;
      }
    }
  }
}

.team-members-container {
  width: calc(100% - 700px);
  margin-top: 50px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  height: 500px;
  overflow-y: auto;

  .title-member-result {
    text-align: left;
    margin-bottom: 20px;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 600;
    color: #333;
    font-size: 35px;
    grid-column: span 2;
  }

  .team-member-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .team-member-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;

      .team-member-image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 15px;
      }

      .team-member-name {
        font-weight: 700;
        color: #333;
      }

      .team-member-designation,
      .team-member-category {
        display: block;
        color: #666;
      }

      .team-member-linkedin {
        display: block;
        margin-top: 10px;
        color: #00a5a2;
        font-weight: 500;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: #008d8b;
        }
      }
    }

    .team-member-actions {
      display: flex;
      gap: 10px;

      button {
        padding: 5px 10px;
        font-size: 14px;
        color: #fff;
        background-color: #00a5a2;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #008d8b;
        }
      }
    }
  }
}
